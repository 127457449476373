var CurrentWindowClientsList;
$(document).on('click', '.WindowClientsContainer .WindowClientsHeadersContainer .WindowClientsScrollListContainer .WindowClientsScrollButtonLeft', WindowClientsScrollLeft);
$(document).on('click', '.WindowClientsContainer .WindowClientsHeadersContainer .WindowClientsScrollListContainer .WindowClientsScrollButtonRight', WindowClientsScrollRight);
$(document).ready(function(){
    GetWindowClients();
});
function WindowClientsScrollLeft(event){
    event.preventDefault();
    var List = $(this).parent().parent().parent().find('.WindowClientsList');
    var leftPos = List.scrollLeft();
    List.animate({scrollLeft: leftPos - 500}, 500);
}
function WindowClientsScrollRight(event){
    event.preventDefault();
    var List = $(this).parent().parent().parent().find('.WindowClientsList');
    var leftPos = List.scrollLeft();
    List.animate({scrollLeft: leftPos + 500}, 500);
}
function GetWindowClients(){
    $('.WindowClientsContainer .WindowClientsList').empty();
    PostMethodFunction('/WebPagePetitions/WindowClients/Get', {}, null, WindowClientsShower, null);
}
function WindowClientsShower(WindowClients){
    WindowClients = WindowClients.window_clients;
    var AppendContent = '';
    $.each(WindowClients, function(index, WindowClient){
        AppendContent += '<li class="WindowClientsListItem" value="'+WindowClient.index+'">';
            AppendContent += '<a class="WindowClientsListItem_Link" href="'+WindowClient.link+'" target="_blank">';
                AppendContent += '<img width="1" height="1" style="height:auto;" class="WindowClientsListItem_Image" title="'+WindowClient.name+'" src="/images/window_clients/'+WindowClient.id+"."+WindowClient.img+'" />';
            AppendContent += '</a>';
        AppendContent += '</li>';
    });
    $('.WindowClientsContainer .WindowClientsList').append(AppendContent);
}

